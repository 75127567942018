import { graphql } from 'gql';

import { client } from './client';
import * as GQLTypes from 'gql/graphql';

export { OrgRolePermissionActionEnum } from 'gql/graphql';

export type { AnotherUserInOrgFragment, SessionCreateWithTelegramInput } from 'gql/graphql';
export type CurrentUser = GQLTypes.UserInfoQuery['currentIdentity'];
export type CurrentOrgUser = GQLTypes.UserInfoQuery['currentIdentity']['orgUsers'][0];

graphql(`
  fragment AnotherUserInOrg on OrgUser {
    id
    user {
      id
      name
      email
    }
  }
`);

const USER_INFO_QUERY = graphql(`
  query UserInfo($orgId: ID) {
    currentIdentity {
      ... on Identity {
        orgUsers(orgId: $orgId) {
          organization {
            id
            name
          }
          orgRole {
            id
            name
            orgRolePermissions {
              action
            }
          }
        }
        user {
          id
          email
          name
          isSuperadmin
          # hasTelegram
        }
      }
    }
  }
`);

const AVAILABLE_ORGS_QUERY = graphql(`
  query AvailableOrgs {
    currentIdentity {
      ... on Identity {
        orgUsers {
          organization {
            id
            name
          }
        }
      }
    }
  }
`);

const ORG_USERS_QUERY = graphql(`
  query OrgUsers($orgId: ID!, $orgRoleIds: [ID!]) {
    orgUsers(orgId: $orgId, orgRoleIds: $orgRoleIds) {
      ... on OrgUserConnection {
        nodes {
          ...AnotherUserInOrg
        }
      }
      ... on OrgUsersQueryDomainError {
        code
        message
      }
    }
  }
`);

const SIGN_IN_MUTATION = graphql(`
  mutation SignIn($input: SessionCreateWithPasswordInput!) {
    session {
      createWithPassword(input: $input) {
        sessionTokens {
          accessToken
          refreshToken
          expiresAt
        }

        error {
          code
          message
        }
      }
    }
  }
`);

const SIGN_IN_WITH_TELEGRAM_MUTATION = graphql(`
  mutation SignInWithTelegram($input: SessionCreateWithTelegramInput!) {
    session {
      createWithTelegram(input: $input) {
        sessionTokens {
          accessToken
          expiresAt
          refreshToken
        }

        error {
          code
          message
        }
      }
    }
  }
`);

const LOG_OUT_MUTATION = graphql(`
  mutation LogOut($input: SessionRevokeInput!) {
    session {
      revoke(input: $input) {
        result

        error {
          code
          message
        }
      }
    }
  }
`);

export const userService = {
  async signIn(input: GQLTypes.SessionCreateWithPasswordInput) {
    const result = await client.mutate({
      mutation: SIGN_IN_MUTATION,
      variables: {
        input,
      },
    });

    const { data } = result;

    if (data.session.createWithPassword.error) {
      return Promise.reject(data.session.createWithPassword.error);
    }

    return data.session.createWithPassword.sessionTokens;
  },

  async signInWithTelegram(input: GQLTypes.SessionCreateWithTelegramInput) {
    const result = await client.mutate({
      mutation: SIGN_IN_WITH_TELEGRAM_MUTATION,
      variables: {
        input,
      },
    });

    const { data } = result;

    if (data.session.createWithTelegram.error) {
      return Promise.reject(data.session.createWithTelegram.error);
    }

    return data.session.createWithTelegram.sessionTokens;
  },

  async logOut() {
    const result = await client.mutate({
      mutation: LOG_OUT_MUTATION,
      variables: {
        input: {},
      },
    });

    const { errors, data } = result;

    if (errors) {
      return Promise.reject(result.errors);
    }

    if (data.session.revoke.error) {
      return Promise.reject(data.session.revoke.error);
    }

    return data.session.revoke;
  },

  async getUser(orgId?: string) {
    const result = await client.query({
      query: USER_INFO_QUERY,
      variables: {
        orgId,
      },
    });

    const { errors, data } = result;

    if (errors) {
      return Promise.reject(result.errors);
    }

    return data.currentIdentity;
  },

  async getAvailableOrgs() {
    const result = await client.query({
      query: AVAILABLE_ORGS_QUERY,
    });

    const { errors, data } = result;

    if (errors) {
      return Promise.reject(result.errors);
    }

    return data.currentIdentity.orgUsers;
  },

  async getAnotherUsersInOrg(orgId: string, orgRoleIds?: string[]) {
    const result = await client.query({
      query: ORG_USERS_QUERY,
      variables: {
        orgId,
        orgRoleIds,
      },
    });

    if (result.data.orgUsers.__typename === 'OrgUsersQueryDomainError') {
      return Promise.reject(result.data.orgUsers);
    }

    return result.data.orgUsers.nodes;
  },
};

export default userService;
